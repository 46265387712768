import React from 'react';
import cx from 'classnames';
import s from './Stack.scss';

enum SpaceSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}
interface StackProps {
  children: React.ReactNode | typeof Item;
  forceColumn?: boolean;
  space?: string;
}

interface StackItemProps {
  children: React.ReactNode | Function;
  cell?: string;
}

const Item = ({ children, cell }: StackItemProps) => {
  return (
    <div className={s.stackItem} style={{ flex: cell }}>
      {children}
    </div>
  );
};

const Stack = ({ children, forceColumn, space }: StackProps) => {
  const classNames = cx(
    s.stack,
    space && s[`space-${space}`],
    forceColumn && s.column,
  );
  return <div className={classNames}>{children}</div>;
};

Stack.Item = Item;
Stack.SpaceSize = SpaceSize;

export default Stack;
