import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import s from './ProductImage.scss';
import cx from 'classnames';
import { DefaultImageIcon } from './DefaultImageIcon';
import {
  Image,
  LoadingBehaviorOptions,
  HoverEffectOptions,
  ResizeOptions,
} from 'wix-ui-tpa/Image';
import { useControllerProps } from '../../ControllerContext';
import { useState } from '../../stores';

const ProductImage = () => {
  const {
    state: { checkout },
  } = useState();
  const { isMobile } = useEnvironment();
  const { product, presetVariants } = useControllerProps().giftCardStore;

  const selectedVariant = presetVariants.find(
    (variant) => variant.id === checkout.purchaseOptions.variantId,
  );

  const width = isMobile ? undefined : 452;
  const imageMedia = selectedVariant?.media || product?.media;
  const aspectRatio = imageMedia && imageMedia.width / imageMedia.height;

  const DefaultImage = () => {
    return (
      <div
        className={cx(
          s.defaultImage,
          s.imageContainer,
          isMobile && s.defaultImageMobile,
        )}
      >
        <DefaultImageIcon />
      </div>
    );
  };

  return product && imageMedia?.url ? (
    <Image
      className={s.imageContainer}
      key={imageMedia.url}
      src={imageMedia.url}
      width={width}
      alt={imageMedia.alt_image}
      resize={ResizeOptions.contain}
      aspectRatio={aspectRatio}
      loadingBehavior={LoadingBehaviorOptions.blur}
      hoverEffect={HoverEffectOptions.zoom}
    />
  ) : (
    <DefaultImage />
  );
};

export default ProductImage;
