import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import s from './ProductBuyForSelection.scss';
import { classes } from './ProductBuyForSelection.st.css';
import cx from 'classnames';
import settingsParams from '../../../settingsParams';
import { BoxSelection, BoxSize } from 'wix-ui-tpa/BoxSelection';
import { useState } from '../../stores';

const ProductBuyForSelection = () => {
  const {
    state: { checkout },
    dispatch,
  } = useState();
  const { isMobile } = useEnvironment();
  const settings = useSettings();

  return (
    <>
      <div className={s.textFieldContainer}>
        <div className={s.label}>
          <label>{settings.get(settingsParams.giftCardForLabel)}</label>
        </div>
        <BoxSelection
          data-hook="rise-gift-card-product-buy-for-box-selection"
          name="buyfor-selection"
          className={classes.boxSelection}
          size={BoxSize.small}
          onChange={({ id }) =>
            dispatch({
              type: 'setPurchaseOptions',
              payload: {
                isGift: id === 'someoneElse',
              },
            })
          }
        >
          <BoxSelection.Option
            className={cx(
              s.BoxSelectionOption,
              isMobile && s.BoxSelectionOptionMobile,
            )}
            id="someoneElse"
            checked={checkout.purchaseOptions.isGift}
          >
            {settings.get(settingsParams.someoneElseButton)}
          </BoxSelection.Option>
          <BoxSelection.Option
            className={s.BoxSelectionOption}
            id="myself"
            checked={!checkout.purchaseOptions.isGift}
          >
            {settings.get(settingsParams.myselfButton)}
          </BoxSelection.Option>
        </BoxSelection>
      </div>
    </>
  );
};

export default ProductBuyForSelection;
