import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/yoshi-flow-editor/tpa-settings';

export type IStylesParams = {
  productTitleColor: StyleParamType.Color;
  productTitleFont: StyleParamType.Font;
  productPriceColor: StyleParamType.Color;
  productPriceFont: StyleParamType.Font;
  productDescriptionColor: StyleParamType.Color;
  productDescriptionFont: StyleParamType.Font;
  backgroundColor: StyleParamType.Color;
  imageBorderColor: StyleParamType.Color;
  inputFieldBackgroundColor: StyleParamType.Color;
  inputFieldBorderColor: StyleParamType.Color;
  inputFieldBorderWidth: StyleParamType.Number;
  inputFieldFont: StyleParamType.Font;
  inputFieldColor: StyleParamType.Color;
  inputFieldTitlesColor: StyleParamType.Color;
  inputFieldTitlesFont: StyleParamType.Font;
  ProductVariantsFillColorOpacity: StyleParamType.Color;
  ProductVariantsBorderColor: StyleParamType.Color;
  ProductVariantsBorderWidth: StyleParamType.Number;
  ProductVariantsSelectedBoxColor: StyleParamType.Color;
  ProductVariantsTextColor: StyleParamType.Color;
  ProductVariantsTextFont: StyleParamType.Font;
  ButtonTextFont: StyleParamType.Font;
  ButtonTextColor: StyleParamType.Color;
  ButtonCornerRadius: StyleParamType.Number;
  ButtonBorderColor: StyleParamType.Color;
  ButtonBorderWidth: StyleParamType.Number;
  ButtonFillColorOpacity: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  productTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  productTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title'),
  },
  productPriceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  productPriceFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-S'),
  },
  productDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  productDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-L'),
  },
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  imageBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },
  inputFieldBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  inputFieldBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  inputFieldBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  inputFieldFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  inputFieldColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  inputFieldTitlesColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  inputFieldTitlesFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  ProductVariantsFillColorOpacity: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  ProductVariantsBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  ProductVariantsBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  ProductVariantsSelectedBoxColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  ProductVariantsTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  ProductVariantsTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  ButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  ButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  ButtonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  ButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  ButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  ButtonFillColorOpacity: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
});
